import React, { useState } from 'react';
import { useFirmsCtx } from '../context';
import { useGlobal } from '../../../context/global';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useSnackbar } from 'notistack';

import FirmSettings from './settings/FirmSettings';
import ManageUsers from './manage-users/ManageUsers';
import ApplyTags from './tags/ApplyTags';
import SettingsButton from './settings/SettingsButton';
import ManageUsersButton from './manage-users/ManageUsersButton';
import ApplyTagsButton from './tags/ApplyTagsButton';
import ProtectFirmButton from './protect-firm/ProtectFirmButton';
import ProtectFirm from './protect-firm/ProtectFirm';
import AccountingTaskButton from './AccountingTaskButton';

import EditFirm from './_edit_firm';
import SinglePhotoUpload from '../../../../shared/components/upload_photo/single';

import { IfAnyGranted } from 'react-authorization';
import { usePermission } from '../../../context/permission';
import { API_PATH, API_MANAGE_FIRMS } from '../../../../const/api_paths';
import {
  doAuthenticatedDelete,
  doAuthenticatedPut,
} from '../../../../actions/_methods';

const useStyles = makeStyles((theme) => ({
  form_wrapper: {
    display: 'flex',
    width: '500px',
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export default ({ firm }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { atleastSuperAngel, getMyRoles } = usePermission();
  const { setIsLoading, reloadData } = useFirmsCtx();
  const [edit_mode, setEditMode] = useState(false);
  const [upload_photo_mode, setUploadPhotoMode] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showProtectFirm, setShowProtectFirm] = useState(false);
  const isFirmProtected = firm.protected === 'true';
  const { enqueueSnackbar } = useSnackbar();

  const upload_url = () => {
    return API_PATH(`${API_MANAGE_FIRMS}/${firm.id}/upload`);
  };

  const onDelete = () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete firm: "${firm.name}"`
    );

    if (confirmed) {
      setIsLoading('secondary');
      doAuthenticatedDelete(
        API_MANAGE_FIRMS + '/' + firm.id,
        {},
        {
          general: onSuccess,
          success: onSuccess,
          error: onError,
        }
      );
    }
  };

  const onChangeStatus = (stats) => {
    const enable_disable = stats == 'active' ? 'activate' : 'deactivate';
    if (
      window.confirm(
        `Are you sure you want to ${enable_disable} firm: "${firm.name}"`
      )
    ) {
      const data = {
        status: stats,
      };

      doAuthenticatedPut(`${API_MANAGE_FIRMS}/${firm.id}`, data, {
        general: onSuccess,
        success: onSuccess,
        error: onError,
      });
    }
  };

  const onSuccess = (res) => {
    const status = res.firm.status;
    const deleteMessage = `Successfully removed firm: ${res.firm.name}`;
    const statusMessage = `Successfully updated firm status to ${status}`;
    const message = status === 'deleted' ? deleteMessage : statusMessage;

    reloadData('secondary');
    prompt(message);
  };

  const onError = (res) => {
    let errorMessage = "Unable to perform that action:";
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += " " + res.errors.join(" ");
    }
    reloadData('secondary');
    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
  };

  const onClickEditProject = () => {
    window.location = `/app/manage/projects/step_3/${firm.id}`;
  };

  const onShowEdit = () => {
    setEditMode(true);
  };

  const onShowUploadPhoto = () => {
    setUploadPhotoMode(true);
  };

  const onCancel = () => {
    setEditMode(false);
    setUploadPhotoMode(false);
  };

  const localAfterEdit = (firm, customMessage) => {
    const message = `Successfully edited firm: ${firm.name}`;

    customMessage ? setShowProtectFirm(false) : setEditMode(false);
    reloadData('secondary');
    prompt(customMessage ? customMessage : message);
  };

  const localAfterUploadPhoto = () => {
    const message = 'Successfully uploaded a logo';

    setUploadPhotoMode(false);
    reloadData('secondary');
    prompt(message);
  };

  const EditProjectButton = () => (
    <>
      <IconButton
        color="info"
        className={classes.button}
        onClick={onClickEditProject}
        aria-label="edit project"
      >
        <AccountTreeIcon fontSize="small" />
      </IconButton>
      <small>{firm.tasks_count}</small>
    </>
  );

  const EditButton = () => (
    <IconButton
      color="warning"
      className={classes.button}
      onClick={onShowEdit}
      aria-label="edit"
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );

  const DeleteButton = () => (
    <IconButton
      color="secondary"
      className={classes.button}
      onClick={onDelete}
      aria-label="delete"
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  );

  const DisableButton = () => (
    <IconButton
      color="success"
      className={classes.button}
      onClick={() => onChangeStatus('inactive')}
      aria-label="delete"
    >
      <ToggleOffIcon fontSize="small" />
    </IconButton>
  );

  const EnableButton = () => (
    <IconButton
      color="default"
      className={classes.button}
      onClick={() => onChangeStatus('active')}
      aria-label="delete"
    >
      <ToggleOnIcon fontSize="small" />
    </IconButton>
  );

  const UploadPhotoButton = () => (
    <IconButton
      color="info"
      className={classes.button}
      onClick={onShowUploadPhoto}
      aria-label="upload_photo"
    >
      <AddAPhotoIcon fontSize="small" />
    </IconButton>
  );

  return (
    <div>
      <EditProjectButton />
      {edit_mode && (
        <EditFirm
          firm={firm}
          name={firm.name}
          afterSave={localAfterEdit}
          onCancel={onCancel}
        />
      )}
      <AccountingTaskButton firm={firm} />
      {!edit_mode && <EditButton />}
      {!edit_mode && <DeleteButton />}
      {firm.status == 'active' && <DisableButton />}
      {firm.status == 'inactive' && <EnableButton />}
      {!edit_mode && !upload_photo_mode && <UploadPhotoButton />}
      {!edit_mode && upload_photo_mode && (
        <SinglePhotoUpload
          url={upload_url()}
          afterSave={localAfterUploadPhoto}
          onCancel={onCancel}
        />
      )}
      <SettingsButton setShowSettings={setShowSettings} />
      <ManageUsersButton setShowAddUser={setShowAddUser} />

      <ApplyTagsButton setShowTags={setShowTags} />

      <IfAnyGranted expected={atleastSuperAngel()} actual={getMyRoles()}>
        <ProtectFirmButton
          isFirmProtected={isFirmProtected}
          setShowProtectFirm={setShowProtectFirm}
        />
      </IfAnyGranted>

      <FirmSettings
        firm={firm}
        open={showSettings}
        handleClose={() => setShowSettings(false)}
      />
      <ManageUsers
        firm={firm}
        open={showAddUser}
        handleClose={() => setShowAddUser(false)}
      />
      <ApplyTags
        firm={firm}
        open={showTags}
        handleClose={() => setShowTags(false)}
      />

      <ProtectFirm
        firm={firm}
        open={showProtectFirm}
        afterSave={localAfterEdit}
        isFirmProtected={isFirmProtected}
        handleClose={() => setShowProtectFirm(false)}
      />
    </div>
  );
};
